/* Navbar */

.navbar {
  font-weight: 400; 
  transition:background $action-transition-duration $action-transition-timing-function;
  -webkit-transition:background $action-transition-duration $action-transition-timing-function;
  -moz-transition:background $action-transition-duration $action-transition-timing-function;
  -ms-transition:background $action-transition-duration $action-transition-timing-function;
  box-shadow: none;
  background: $navbar-default-bg;
  .sidebar-dark & {
    box-shadow: none;
  }

  .navbar-brand-wrapper {
    background: $sidebar-light-bg; 
    .sidebar-dark & {
      background: $sidebar-dark-bg;
      @media (max-width: 991px) {
        background: $sidebar-light-bg; 
      }
    }
    transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
    -webkit-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
    -moz-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
    -ms-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
    width: $sidebar-width-lg;
    height: $navbar-height;
    padding: 20px 35px;
    @media (max-width: 991px) {
      padding: 20px 0;
    }
    .navbar-brand {
      color: lighten($gray-dark, 20%);
      font-size: 1.5rem;
      margin-right: 0;
      padding: 0;
      line-height: 1;
      
      &.brand-logo-mini {
        display: none;
      }

      &:active,
      &:focus,
      &:hover {
        color: lighten($gray-dark, 10%);
      }

      img {
        // width: calc(#{$sidebar-width-lg} - 130px );
        max-width: 100%;
        height: 34px;
        margin: auto;
        vertical-align: middle;
      }
    }

    .brand-logo-mini {
      padding-left: 0;
      text-align: center;
      img { 
        width: calc(#{$sidebar-width-icon} - 30px );
        max-width: 100%;
        margin: auto;
      }
    }
    .navbar-toggler {
      border: 0;
      color: inherit;
      font-size: 1rem;
      padding: 0;
      border-radius: 0;
      &:focus {
        box-shadow: none;
      }
      &:not(.navbar-toggler-right) {
        @media (max-width: 991px) {
          display: none;
        }
        transform: rotate(0deg);
        @include transition(transform .3s linear);
        .sidebar-icon-only & {
          transform: rotate(180deg);
        }
      }
      &.navbar-toggler-right{
        @media (max-width:991px){
          padding-left: 15px;
          padding-right: 0;
        }
      }
      .sidebar-dark & {
        padding-left: 0;
        color: $white;
      }
    }
  }

  .navbar-menu-wrapper {
    background: $navbar-default-bg;
    transition: width $action-transition-duration $action-transition-timing-function;
    -webkit-transition: width $action-transition-duration $action-transition-timing-function;
    -moz-transition: width $action-transition-duration $action-transition-timing-function;
    -ms-transition: width $action-transition-duration $action-transition-timing-function;
    color: $navbar-menu-color;
    padding-left: 3.5rem;
    padding-right: 2.187rem;
    padding-top: 30px;
    padding-bottom: 25px;
    width: calc(100% - #{$sidebar-width-lg});
    height: $navbar-height;
    @media (max-width: 991px) {
      width: calc(100% - 55px);
      padding-top: 12px;
      padding-left: 15px;
      padding-right: 11px;
      height: auto;
    }
    .navbar-toggler {
      border: 0;
      color: inherit;
      font-size: 1.25rem;
      padding: 0;
      border-radius: 0;
      &:not(.navbar-toggler-right) {
        @media (max-width: 991px) {
          display: none;
        }
        transform: rotate(0deg);
        @include transition(transform .3s linear);
        .sidebar-icon-only & {
          transform: rotate(180deg);
        }
      }
      &.navbar-toggler-right{
        @media (max-width:991px){
          padding-left: 15px;
          padding-right: 0;
          &:focus {
            box-shadow: none;
          }
        }
      }
      .sidebar-dark & {
        padding-left: 2.375rem;
      }
    }

    .navbar-nav {
      @extend .d-flex;
      @extend .flex-row;
      @extend .align-items-center;
      .nav-item {
        margin-left: 1.25rem;
          @media (max-width: 992px) {
            margin-left: 10px;
            margin-right: 0px;
          }
        &:last-child {
          margin-right: 0;
        }
        .nav-link {
          color: $navbar-link-color;
          font-size: $navbar-font-size;
          vertical-align: middle;

          @media (max-width: 767px) {
            margin-left: 0.5rem;
            margin-right: 0.5rem;
          }

          i {
            font-size: 1.25rem;
            vertical-align: middle;
            margin-right: 0;
            &.icon-lg {
              font-size: 1.4rem;
            }
          }
        }
        .small-text {
          font-size: 0.75rem;
        }
        &.nav-search {
          margin-left: 2rem;
          .input-group {
            border-radius: 4px;
            padding: .75rem;
            .form-control,
            .input-group-text {
              background: transparent;
              border: 0;
              color: $black;
              padding: 0;
            }
            .input-group-text {
              i {
                font-size: 1.25rem;
                color: $navbar-menu-color;
              }
            }
            .form-control {
              margin-left: .7rem;
              @include placeholder {
                color: #6c7383;
              }
            }
          }
        }
        &.nav-settings {
          @extend .align-self-stretch;
          @extend .align-items-center;
          margin: 0;
          .nav-link {
            padding: 0;
            text-align: center;
            height: 34px;
          }
          i {
            font-size: 1.25rem;
            vertical-align: middle;
          }
        }
        &.nav-profile {
          @extend .d-flex;
          @extend .align-items-center;
          margin-left: .2rem;
          img {
            width: 40px;
            height: 40px;
            border-radius: 100%;
          }
          &.dropdown {
            .dropdown-toggle {
              &:after {
                display: none;
              }
            }
          }  
        }
        &.dropdown {
          .dropdown-menu {
            @extend .dropdownAnimation;        
            border: none;
            -webkit-box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.2);
            box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.2);
          }
          .navbar-dropdown {
            position: absolute;            
            font-size: 0.9rem;
            margin-top: 0;
            right: 0;
            left: auto;
            top: 70px;
            padding-top: 0;
            .rtl & {
              right: auto;
              left: 0;
            }
            .dropdown-item {
              @extend .d-flex;
              @extend .align-items-center;
              margin-bottom: 0;
              padding: 5px 25px;
              cursor: pointer;
              border-bottom: 1px solid $border-color;
              line-height: 1;
              i {
                font-size: 24px;
              }

              .badge {
                margin-left: 2.5rem;
              }
              .ellipsis {
                max-width: 200px;
                overflow: hidden;
                text-overflow: ellipsis;
                line-height: 1;
              }
              .rtl & {
                i {
                  @extend .me-0;
                  margin-left: 10px;
                }
                .badge {
                  margin-left: 0;
                  margin-right: 2.5rem;
                }
              }
            }

            .dropdown-divider {
              margin: 0;
            }
          }
          i {
            margin-right: 0;
            vertical-align: middle;
          }
          @media (max-width: 991px) {
            position: static;
            .navbar-dropdown {
              left: 20px;
              right: 20px;
              top: $navbar-height;
              width: calc(100% - 40px);
            }
          }
          .count-indicator {
            position: relative;
            padding: 0;
            text-align: center;
            .count {
              position: absolute;
              left: 45%;
              width: 7px;
              height: 7px;
              border-radius: 100%;
              background: $danger;
              color: $white;
              font-size: 11px;
              top: -2px;
              font-weight: 600;
              line-height: 1rem;
              border: 1px solid $white;
              text-align: center;
          }
            &:after {
              display: none;
            }
          }
        }

        .welcome-text {
          font-family: $type1;
          font-style: normal;
          font-weight: normal;
          font-size: 28px;
          line-height: 38px;
          color: $welcome-grey;
          margin-bottom: 10px;
          .text-dark {
            color: $black;
          }
        }
        .welcome-sub-text {
          font-family: $type1;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 22px;
          color: $welcome-grey;
          margin-bottom: 0;
        }
        .dropdown-bordered {
          padding: 8px 15px;
          border: 1px solid $border-grey;
          border-radius: 6px;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          font-family: $type1;
          min-width: 170px;
          height: 34px;
          background: $white;
          &.dropdown-toggle {
            &:after {
              position: absolute;
              right: 15px;
              top: 35%;
              margin-left: auto;
              color: $black;
            }
          }
        }
        .navbar-date-picker {
          background: $white;
          font-family: $type1;
          height: 36px;
          border: 1px solid $border-grey;
          border-radius: 6px;
          .calendar-icon {
            color: $black;
            font-size: 12px;
          }
          .input-group-prepend {
            height: 34px;
            border-color:transparent;
            border-radius: 6px 0 0 6px;
            background: r=transparent;
            border-right: 1px solid $border-color;
            & > .input-group-text {
              border-color:transparent;
              border-right: 1px solid $border-color;
              background: transparent;
              height: 100%;

            }
          }
          .form-control {
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            color: $black;
            border: none;
            border-left: none;
            max-width: 120px;
            height: 34px;
            border-radius: 0 6px 6px 0;
          }
        }
        .search-form {
          position: relative;
          margin-right: 0;
          vertical-align: middle;
          height: 14px;

          .rtl & {
            margin-right: 0;
            margin-left: 0;
          }

          i {
            font-size: 1.25rem;
            position: absolute;
            left: 0;
            top: 60%;
            transform: translateY(-50%);
            color: $navbar-menu-icon-color;

            .rtl & {
              left: auto;
              right: 0;
            }
          }

          input{
            background-color: transparent;
            border: none;
            cursor: pointer;
            width: 0;
            height: 24px;
            padding: 0 0 0 20px;
            position: relative;
            transition: width 400ms ease, background 400ms ease;

            &:focus{
              background-color: transparent;
              border-bottom: 1px solid $navbar-menu-icon-color;
              border-radius: 0;
              padding-left: 30px;
              cursor: text;
              outline: 0;
              width: 140px;

              .rtl & {
                padding-right: 30px;
                padding-left: 0;
              }
            }
          }
        }
      }
      &.navbar-nav-right {
        @extend .align-self-stretch;
        @extend .align-items-stretch;
        .nav-item {
          @extend .d-flex;
          @extend .align-items-center;
          &.nav-settings {
            @extend .d-none;
            @extend .d-lg-flex;
          }
        }
        @media (min-width: 992px) {
          margin-left: auto;
          .rtl & {
            margin-left: 0;
            margin-right: auto;
          }
        }
      }
    }
  }

  /* Navbar color variations */
  @each $color, $value in $theme-colors {
    &.navbar-#{$color} {
      .navbar-menu-wrapper {
        background: $value;
      }
      &:not(.navbar-light) {
        .navbar-menu-wrapper {
          .navbar-toggler {
            color: $white;
          }
          .nav-item {
            .nav-link {
              color: $white;
            }
            &.nav-search {
              .input-group {
                .form-control {
                  color: $white;
                  @include placeholder {
                    color: $white;
                  }
                }
                .input-group-text { 
                  i {
                    color: $white;
                  }
                }
              }
            }
          }
        }
      }
    }
    &.navbar-light {
      .navbar-menu-wrapper {
        background: $white;
        border-left: 1px solid $border-color;
        .navbar-toggler {
          color: $dark;
        }
        .nav-item {
          .nav-link {
            color: $dark;
          }
          &.nav-search {
            .input-group {
              background: rgba($dark, .24);
            }
          }
        }
      }
    }
    &.navbar-dark {
      .navbar-menu-wrapper {
        border-left-color: darken($border-color, 50%);
      }
    }
    &.navbar-danger {
      .navbar-menu-wrapper {
        .nav-item {
          .nav-link {
            &.count-indicator {
              .count {
                background: $primary;
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width:991px) {
  .navbar {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    .navbar-brand-wrapper {
      width: 55px;
      .navbar-brand {
        &.brand-logo {
          display: none;
        }
        &.brand-logo-mini {
          display: inline-block;
        }
      }
    }
  }

  .navbar-collapse {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: 0.5rem;
  }
}

@media (max-width:480px) {
  .navbar {
    .navbar-brand-wrapper {
      width: 55px;
      .brand-logo-mini{
        padding-top: 0px;
      }
    }
  }
}