.profile-navbar {
	.nav-item {
		.nav-link {
			color: $body-color;
			&.active {
				color: $primary;
			}
			i {
				font-size: 1.25rem;
			}
		}
	}
}
.profile-feed-item {
	padding: 1.5rem 0;
	border-bottom: 1px solid $border-color;	
}